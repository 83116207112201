<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Form <span>Rename</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <div class="form_grp">
                        <Form @submit="handleSubmitForm" v-slot="{ errors }" ref="rename-form-form">
                            <div class="group_item">
                                <label class="input_label">Form Name</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.survey_name }">
                                    <Field autocomplete="off" type="text" name="survey_name" v-model="form.survey_name" placeholder="Runner jogging in the morning in nature" rules="required" />
                                </div>
                                <ErrorMessage name="survey_name" class="text-danger" />
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button :disabled="loader" type="button" class="btn save_btn" @click="handleSubmitForm"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Rename Form',

        data () {
            return {
                form: {
                    survey_name: '',
                    id: '',
                }
            }
        },

        props: {
            modelValue: Boolean,
            activeForm: Object,
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.form = {
                        survey_name: vm.activeForm.survey_name,
                        id: vm.activeForm.id,
                    }
                }
            }
        },

        computed: mapState({
            loader: state => state.formModule.formCreateLoader,
        }),

        methods: {
            ...mapActions({
                renameForm: 'formModule/renameForm'
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSubmitForm () {
                const vm = this;

                const renameForm = vm.$refs['rename-form-form'];

                renameForm.validate().then((result) => {
                    if (result.valid) {
                        vm.handleRenameForm(renameForm);
                    }
                });
            },

            handleRenameForm (form) {
                const vm = this;

                vm.form.setFieldError = form.setFieldError;

                vm.renameForm(vm.form).then((result) => {
                    if (result) {
                        if (vm.$parent.$parent && vm.$parent.$parent.refreshForm) {
                            vm.$parent.$parent.refreshForm();
                        } else {
                            vm.$parent.refreshForm();
                        }

                        vm.closeModal();
                    }
                });
            }
        }
    }
</script>
